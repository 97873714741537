/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { MedicareEligibilityValues } from './MedicareCalculator';

function MedicareCalculatorResults({
  values,
  scenario,
  selectedMed,
}: {
  values: MedicareEligibilityValues;
  scenario: string;
  selectedMed: any;
}) {
  const [buttonUrl, setButtonUrl] = useState<string>(
    'https://app.transparentpricerx.com/enroll?utm_source=brokerportal&utm_id=medicare&utm_campaign=chc&company=Medicare'
  );
  const [resultHeigth, setResultHeight] = useState(0);
  const getDeviceType = useCallback(() => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }, []);
  const deviceType = getDeviceType();
  const appendUrlParametersToLinks = useCallback(() => {
    // Create a URLSearchParams object based on the current URL's search part
    const searchParams = new URLSearchParams(window.location.search);
    // Check if there are any search parameters
    if (Array.from(searchParams.keys()).length > 0) {
      // Create a URL object based on the link's href attribute
      const linkUrl = new URL(buttonUrl);
      // Append each search parameter to the link's URL
      searchParams.forEach((value, key) => {
        linkUrl.searchParams.set(key, value);
      });
      setButtonUrl(linkUrl.toString());
    }
  }, [buttonUrl]);

  useEffect(() => {
    appendUrlParametersToLinks();
  }, [appendUrlParametersToLinks, values]);

  useEffect(() => {
    const stackElement = document.getElementById(
      `medicare-eligibility-results`
    );
    if (stackElement) {
      setResultHeight(
        deviceType === 'desktop'
          ? 350 + Number(stackElement.clientHeight)
          : 550 + Number(stackElement.clientHeight)
      );
    }
  }, [selectedMed, deviceType]);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'resize',
        value: resultHeigth,
      },
      '*'
    );
  }, [resultHeigth]);
  return (
    <>
      <Stack
        id={'medicare-eligibility-results'}
        key={scenario}
        spacing={2}
        width={{
          xs: '100%',
          md: '70rem',
        }}
        textAlign={'center'}
        margin={'auto'}
        p={{
          xs: 2,
          md: 4,
        }}
        sx={{
          backgroundColor: '#f4f9fe',
          borderRadius: 4,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        }}>
        {scenario === 'medicareGeneric' && (
          <>
            <Typography variant="h3">
              The generic medication cost by strength is as follows:
            </Typography>
            <Grid container justifyContent="center" spacing={1}>
              {selectedMed.options.map((option: any) => (
                <Grid item key={option.strength}>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="body1" fontWeight={600}>
                      {option.strength}:
                    </Typography>
                    <Typography variant="body1">{option.price}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Stack textAlign={'left'} spacing={1}>
              <Typography variant="body1" fontWeight={600}>
                Transparent Pricing:
              </Typography>
              <Typography variant="body1">
                The client will pay directly to a Non-Profit Pharmacy
                Manufacturer, ensuring fair pricing.
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                Delivery Time:{' '}
              </Typography>
              <Typography variant="body1">
                1 to 2 weeks: Once the healthcare provider eFaxed the paperwork
                Directly to the Manufacturer
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                Please ensure the client understands and follows these
                instructions carefully.
              </Typography>
              <Typography variant="body1">
                This will help to avoid any delays and ensure a smooth
                processing of their medication requests.
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={buttonUrl}>
              Enroll Now
            </Button>
          </>
        )}

        {scenario === 'medicareBrand' && (
          <>
            <Typography variant="h2"> Qualify for PAP!</Typography>
            <Stack textAlign={'left'} spacing={1}>
              <Typography variant="body1" fontWeight={600}>
                Strict Compliance Required:
              </Typography>
              <Typography variant="body1">
                The client must adhere precisely to the provided steps, as there
                are no alternative methods. This adherence is essential to meet
                manufacturer regulations. Print out and wet ink sign the
                application.
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                Income verification:
              </Typography>
              <Typography variant="body1">
                Provide income verification documents to their healthcare
                provider, attached to the application for it to be faxed
                directly to the manufacturer.
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                Delivery Time: 2 to 3 weeks:
              </Typography>
              <Typography variant="body1">
                Once the healthcare provider eFaxed the paperwork Directly to
                the Manufacturer.
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={buttonUrl}>
              Enroll Now
            </Button>
          </>
        )}
      </Stack>
    </>
  );
}

export default MedicareCalculatorResults;
