/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import '../../App.css';
import { useEffect, useState } from 'react';
import logo from '../../assets/images/full logo.png';
import { LineChart } from '@mui/x-charts/LineChart';
import theme from '../../theme';
import { collection, DocumentData } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';

function groupClientsByDate(clients: any, timeGranularity: string) {
  const groupedByDate: { [key: string]: any[] } = {};
  if (!clients) return;
  if (timeGranularity === 'hour') {
    // Group by hours for today
    clients.forEach((client: any) => {
      if (!client.time) return;
      const date = new Date(
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000
      );
      const today = new Date();
      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        const hour = date.getHours();
        if (!groupedByDate[hour]) {
          groupedByDate[hour] = [];
        }
        groupedByDate[hour].push(client);
      }
    });
  } else if (timeGranularity === 'day') {
    // Group by days for the past 28 days
    const oneMonthAgo = new Date();
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 28);
    clients.forEach((client: any) => {
      if (!client.time) return;
      const date = new Date(
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000
      );
      if (date >= oneMonthAgo) {
        const day = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
          .getDate()
          .toString()
          .padStart(2, '0')}`; // MM/DD
        if (!groupedByDate[day]) {
          groupedByDate[day] = [];
        }
        groupedByDate[day].push(client);
      }
    });
  } else if (timeGranularity === 'month') {
    // Group by months for the past 12 months
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    clients.forEach((client: any) => {
      if (!client.time) return;
      const date = new Date(
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000
      );
      if (date >= oneYearAgo) {
        const month = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`; // YYYY-MM
        if (!groupedByDate[month]) {
          groupedByDate[month] = [];
        }
        groupedByDate[month].push(client);
      }
    });
  } else if (timeGranularity === 'platform') {
    // Group by 1st of November 2024
    const firstNov = new Date('2024-11-01');

    clients.forEach((client: any) => {
      if (!client.time) return;
      const date = new Date(
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000
      );
      if (date >= firstNov) {
        const platform = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`; // YYYY-MM
        if (!groupedByDate[platform]) {
          groupedByDate[platform] = [];
        }
        groupedByDate[platform].push(client);
      }
    });
  }
  return groupedByDate;
}
const generateFullRangeLabels = (timeGranularity: string) => {
  const labels = [];
  if (timeGranularity === 'hour') {
    // Generate labels for all 24 hours
    for (let i = 0; i < 24; i++) {
      labels.push(`${i}`);
    }
  } else if (timeGranularity === 'day') {
    // Generate labels for the past 28 days
    const today = new Date();
    for (let i = 0; i < 28; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      labels.push(
        `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
          .getDate()
          .toString()
          .padStart(2, '0')}`
      ); // MM/DD
    }
    labels.reverse(); // To have the oldest date first
  } else {
    // Generate labels for the past 12 months
    const today = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(today);
      date.setMonth(today.getMonth() - i);
      labels.push(
        `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`
      ); // YYYY-MM
    }
    labels.reverse(); // To have the oldest month first
  }
  return labels;
};

function calculateMoney(timeGranularity: string, applicantsList: any) {
  let totalAmount = 0;
  if (!applicantsList) return '0';

  if (timeGranularity === 'hour') {
    const clientsJoinedToday = applicantsList.filter((client: any) => {
      if (!client.time || !client.amount) return false;
      const clientJoinDate =
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000;
      const clientDate = new Date(clientJoinDate);
      const today = new Date();
      return (
        clientDate.getDate() === today.getDate() &&
        clientDate.getMonth() === today.getMonth() &&
        clientDate.getFullYear() === today.getFullYear()
      );
    });

    totalAmount = clientsJoinedToday.reduce(
      (sum: number, client: any) => sum + Number(client.amount / 100),
      0
    );
  } else if (timeGranularity === 'day') {
    const clientsJoinedInPastMonth = applicantsList.filter((client: any) => {
      if (!client.time || !client.amount) return false;
      const clientJoinDate =
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000;
      const clientDate = new Date(clientJoinDate);
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      return clientDate >= oneMonthAgo;
    });

    totalAmount = clientsJoinedInPastMonth.reduce(
      (sum: number, client: any) => sum + Number(client.amount / 100),
      0
    );
  } else if (timeGranularity === 'month') {
    const clientsJoinedInPastYear = applicantsList.filter((client: any) => {
      if (!client.time || !client.amount) return false;
      const clientJoinDate =
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000;
      const clientDate = new Date(clientJoinDate);
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      return clientDate >= oneYearAgo;
    });
    totalAmount = clientsJoinedInPastYear.reduce(
      (sum: number, client: any) => sum + Number(client.amount / 100),
      0
    );
  } else if (timeGranularity === 'platform') {
    const clientsJoinedNov = applicantsList.filter((client: any) => {
      if (!client.time || !client.amount) return false;
      const clientJoinDate =
        client.time.seconds * 1000 + client.time.nanoseconds / 1000000;
      const clientDate = new Date(clientJoinDate);
      const firstNov = new Date('2024-11-01');
      return clientDate >= firstNov;
    });
    totalAmount = clientsJoinedNov.reduce(
      (sum: number, client: any) => sum + Number(client.amount / 100),
      0
    );
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(totalAmount);
}

function InsurancePortal() {
  const [applicants, loadingApplicants] = useCollection(
    collection(db, 'chcDashboardClients'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );
  const [applicantsList, setApplicantList] = useState<DocumentData[] | null>(
    null
  );
  const [numOfAgents, setNumOfAgents] = useState<number>(0);
  const [numOfClients, setNumOfClients] = useState<number>(0);

  useEffect(() => {
    if (loadingApplicants) return;

    if (applicants) {
      const aplicantList = applicants.docs.map((doc) => doc.data());
      setApplicantList(aplicantList);
      setNumOfAgents(
        new Set(
          aplicantList
            .map((applicant) => applicant.agentName)
            .filter((agentName) => agentName !== undefined)
        ).size
      );
      setNumOfClients(
        new Set(aplicantList.map((applicant) => applicant.email)).size
      );
    }
  }, [loadingApplicants, applicants]);
  const [timeGranularity, setTimeGranularity] = useState<
    'hour' | 'day' | 'month' | 'platform'
  >('platform');
  const groupedClients = groupClientsByDate(applicantsList, timeGranularity);
  const [totalProfitInPreiod, setTotalProfitInPreiod] = useState<string>('0');
  const fullRangeLabels = generateFullRangeLabels(timeGranularity);
  const formattedData = fullRangeLabels.map((key) => ({
    date: key,
    count: groupedClients?.[key]?.length ?? 0,
  }));
  useEffect(() => {
    setTotalProfitInPreiod(calculateMoney(timeGranularity, applicantsList));
  }, [applicantsList, timeGranularity]);
  if (loadingApplicants) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Stack
      spacing={2}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      sx={{
        paddingTop: {
          sm: 2,
          md: 4,
        },
      }}>
      <Stack spacing={4} className="container">
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            paddingBottom: '1.6rem',
            borderBottom: '1px solid #E0E0E0',
          }}>
          <img
            src={logo}
            alt="TPRX Logo"
            loading="lazy"
            height={'30'}
            width={'auto'}
          />
          <img
            src={
              'https://cdn.prod.website-files.com/64c1145cbf2b6e07020d3b41/656312b7e79197d7d7b24057_chcLogo.png'
            }
            alt="CHC Logo"
            loading="lazy"
            height={'45'}
            width={'auto'}
          />
        </Stack>
        <Typography variant="h1" textAlign={'center'}>
          Portal Metrics Compass Health Advisors
        </Typography>
        <Stack direction="row" spacing={2} pl={2}>
          <TextField
            sx={{ width: '20rem' }}
            select
            value={timeGranularity}
            label="Time Period"
            variant="outlined"
            onChange={(e) => setTimeGranularity(e.target.value as any)}>
            <MenuItem value="platform">From November 1st</MenuItem>
            <MenuItem value="hour">Today</MenuItem>
            <MenuItem value="day">Past 28 days</MenuItem>
            <MenuItem value="month">Past year</MenuItem>
          </TextField>
        </Stack>
        <Grid
          container
          spacing={2}
          alignContent={'stretch'}
          justifyContent={'stretch'}>
          <Grid item xs={12} sm={8}>
            <Stack
              className="tprx-card "
              position={'relative'}
              sx={{
                padding: '6.4rem 1.6rem  1.6rem 1.6rem',
              }}>
              <LineChart
                sx={{
                  '& .MuiAreaElement-root': {
                    fill: 'rgba(7, 104, 144, 0.10)',
                    stroke: theme.palette.primary.main,
                  },
                  '& .MuiChartsLegend-mark': {
                    display: 'none',
                  },
                }}
                xAxis={[
                  {
                    dataKey: 'date',
                    scaleType: 'band',
                    tickLabelStyle: {
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      fill: theme.palette.text.secondary,
                    },
                    tickLabelPlacement: 'tick',
                    tickPlacement: 'middle',
                  },
                ]}
                leftAxis={null}
                series={[
                  {
                    color: theme.palette.primary.main,
                    dataKey: 'count',
                    showMark: false,
                    area: true,
                  },
                ]}
                grid={{ horizontal: true }}
                dataset={formattedData}
                height={400}
                skipAnimation
                tooltip={{ trigger: 'none' }}
              />
              <Stack
                spacing={2}
                position={'absolute'}
                top={'1.6rem'}
                left={'1.6rem'}>
                <Typography variant="h4" fontWeight={500}>
                  Revenue
                </Typography>
                <Stack spacing={2} direction={'row'}>
                  <Typography variant="h1">{totalProfitInPreiod}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  className="tprx-card tight"
                  alignItems={'start'}
                  justifyContent={'center'}>
                  <Typography variant="h4" fontWeight={500}>
                    Total Number of Applicants
                  </Typography>
                  <Stack spacing={2} direction={'row'}>
                    <Typography variant="h1">{numOfClients}</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  className="tprx-card tight"
                  alignItems={'start'}
                  justifyContent={'center'}>
                  <Typography variant="h4" fontWeight={500}>
                    Total Number of Agents
                  </Typography>
                  <Stack spacing={2} direction={'row'}>
                    <Typography variant="h1">{numOfAgents}</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
export default InsurancePortal;
