/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import useGooglePlaces from '../../utils/useGooglePlaces';
import { FormControl } from '@mui/material';

interface AutocompleteFieldProps {
  label: string;
  name: string;
  value: string;
  onChange: (addressValues: any) => void;
  errors?: string;
  setErrors?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  errorObjectName?: string;
}

const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  label,
  name,
  value,
  onChange,
  errors,
  setErrors,
  errorObjectName,
}) => {
  const { ref, fullAddress, setFullAddress } = useGooglePlaces((place) => {
    const addressComponents = place.address_components;
    const formattedAddress = place.formatted_address;
    const updatedValues: any = {};
    if (!addressComponents) return;
    addressComponents.forEach(
      (component: {
        types: string[];
        long_name: string;
        short_name: string;
      }) => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number': {
            updatedValues.address =
              component.long_name !== 'undefined' && `${component.long_name} `;
            break;
          }
          case 'route': {
            updatedValues.address += component.short_name;
            break;
          }
          case 'locality':
            updatedValues.city = component.long_name;
            break;
          case 'administrative_area_level_1': {
            updatedValues.state = component.short_name;
            break;
          }
        }
      }
    );
    updatedValues.fullAddress = formattedAddress;
    onChange({ ...updatedValues });
    setFullAddress(formattedAddress);
  }, value);

  useEffect(() => {
    if (value) setFullAddress(value);
  }, [setFullAddress, value]);

  return (
    <FormControl fullWidth sx={{ marginTop: '1.6rem' }}>
      <TextField
        placeholder={value || ''}
        fullWidth
        label={label}
        required
        name={name}
        error={!!errors}
        helperText={errors || ''}
        inputRef={ref}
        value={fullAddress || ''}
        onChange={(e) => {
          if (e.target.value === '') {
            onChange({
              address: '',
              city: '',
              state: '',
            });
            setErrors!({
              [`${errorObjectName}`]: 'This field is required',
            });
          }

          setFullAddress(e.target.value);
        }}
      />
    </FormControl>
  );
};

export default AutocompleteField;
