/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Divider,
  Drawer,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import InsuranceCostDialog from './InsuranceCostDialog';
import { eligibilityValues } from './eligibilityCalculator';

function EligibilityCalculatorResults({
  values,
  scenario,
  insuranceDataList,
  selectedMed,
  drawerContent,
  isPAP,
  source,
}: {
  values: eligibilityValues;
  scenario: string;
  insuranceDataList: DocumentData[] | null;
  selectedMed: any;
  drawerContent: string | null;
  isPAP: boolean;
  source: string;
}) {
  let globalIndex = 0;
  const [openCostDialog, setOpenCostDialog] = useState<boolean>(false);
  const [medicationDialogData, setMedicationDialogData] = useState<any>(null);
  const [unsupportedInsurances, setUnsupportedInsurancesPlans] = useState<
    string[]
  >([]);
  const [rcsprices, setRcsPrices] = useState<any>(null);
  const [buttonUrl, setButtonUrl] = useState<string>(
    `https://app.transparentpricerx.com/enroll?utm_source=brokerportal&utm_id=TPRX&utm_campaign=${source}`
  );
  const handleClose = () => {
    setOpenCostDialog(false);
    setMedicationDialogData(null);
  };
  const handleClickOpen = (rowData: any) => {
    setMedicationDialogData(rowData);
    setOpenCostDialog(true);
  };
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (
      !selectedMed ||
      !selectedMed.insurance ||
      selectedMed.insurance.length === 0
    )
      return;
    setUnsupportedInsurancesPlans([]);
    selectedMed.insurance.forEach((insurance: any) => {
      for (const product of insurance.products) {
        for (const plan of product.plans) {
          if (
            plan.prices.some(
              (option: any) =>
                option.name === 'Unavailable' ||
                option.name === 'PAP' ||
                option.price ===
                  'No Real Cost Saving Program available for this Medication'
            )
          ) {
            setUnsupportedInsurancesPlans((prev) => {
              if (!prev.includes(product.name)) {
                return [...prev, product.name];
              }
              return prev;
            });
          }
        }
      }
    });
    const insurance = selectedMed.insurance.filter(
      (insurance: any) => insurance.name === values.company
    );
    if (insurance.length === 0) return;
    const product = insurance[0].products.filter(
      (product: any) => product.name === values.product
    );
    if (product.length === 0) return;
    const plan = product[0].plans.filter(
      (plan: any) => plan.name === values.plan
    );
    if (plan.length === 0) return;
    setRcsPrices(plan[0]);
  }, [selectedMed, values.company, values.plan, values.product]);

  const appendUrlParametersToLinks = useCallback(() => {
    // Create a URLSearchParams object based on the current URL's search part
    const searchParams = new URLSearchParams(window.location.search);
    // Check if there are any search parameters
    if (Array.from(searchParams.keys()).length > 0) {
      // Create a URL object based on the link's href attribute
      const linkUrl = new URL(buttonUrl);

      // Append each search parameter to the link's URL
      searchParams.forEach((value, key) => {
        linkUrl.searchParams.set(key, value);
      });
      setButtonUrl(linkUrl.toString());
    }
  }, [buttonUrl]);

  useEffect(() => {
    appendUrlParametersToLinks();
  }, [appendUrlParametersToLinks, values]);

  return (
    <>
      <Stack
        key={scenario}
        spacing={2}
        width={{
          xs: '100%',
          md: '70rem',
        }}
        textAlign={'center'}
        margin={'auto'}
        p={{
          xs: 2,
          md: 4,
        }}
        sx={{
          backgroundColor: '#f4f9fe',
          borderRadius: 4,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        }}>
        {scenario === 'generic' && (
          <>
            <Typography variant="h3">
              The generic medication cost by strength is as follows:
            </Typography>
            <Grid container justifyContent="center" spacing={2}>
              {selectedMed.options.map((option: any) => (
                <Grid item key={option.strength}>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="body1" fontWeight={600}>
                      {option.strength}:
                    </Typography>
                    <Typography variant="body1">{option.price}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={buttonUrl}>
              Enroll Now
            </Button>
          </>
        )}
        {scenario === 'medUnavailable' && (
          <>
            <Typography variant="h3">
              This medication is not covered by the current insurance product.
            </Typography>
            {selectedMed.name === 'Mounjaro' && (
              <Typography variant="h4" fontWeight={500}>
                We also offer a generic version of this medication under the
                name Wegovy which does not require any income nor insurance
                eligibility
              </Typography>
            )}
          </>
        )}
        {scenario === 'papOnlyMedNotEligible' && (
          <>
            <Typography variant="h3">
              Your client does not qualify for the Patient Assistance Program
              (PAP) because their income exceeds the eligibility threshold
            </Typography>
            {selectedMed.name === 'Mounjaro' ||
              (selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              ))}
            {unsupportedInsurances.length === 21 && (
              <Typography variant="h3" fontWeight={500}>
                Please search for an alternative medication in our medication
                list.
              </Typography>
            )}
          </>
        )}
        {scenario === 'papOnlyMedEligible' && (
          <>
            <Typography variant="h3">
              This medication is not covered by the current insurance product.
            </Typography>
            {selectedMed.name === 'Mounjaro' ||
              (selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              ))}
          </>
        )}
        {scenario === 'papEligible' && (
          <>
            <Typography variant="h2"> Qualify for PAP!</Typography>
            <Typography variant="h3">
              The cost for {selectedMed.name} is as follows:
            </Typography>
            <Grid container justifyContent="center" spacing={1}>
              {selectedMed.options.map((option: any) => (
                <Grid item key={option.strength}>
                  <Stack direction={'row'}>
                    <Typography variant="body1" fontWeight={600}>
                      {option.strength}:
                    </Typography>
                    <Typography variant="body1">{option.price}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Stack textAlign={'left'} spacing={1} pb={2}>
              <Typography variant="body1" fontWeight={600}>
                Strict Compliance Required:
              </Typography>
              <Typography variant="body1">
                The client must adhere precisely to the provided steps, as there
                are no alternative methods. This adherence is essential to meet
                manufacturer regulations. Print out and wet ink sign the
                application.
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                Income verification:
              </Typography>
              <Typography variant="body1">
                Provide income verification documents to their healthcare
                provider, attached to the application for it to be faxed
                directly to the manufacturer.
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                Delivery Time: 2 to 3 weeks:
              </Typography>
              <Typography variant="body1">
                Once the healthcare provider eFaxed the paperwork Directly to
                the Manufacturer.
              </Typography>
            </Stack>

            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={buttonUrl}>
              Enroll Now
            </Button>
          </>
        )}

        {scenario === 'papIncomeHigh' && (
          <>
            <Typography variant="h3">
              Your client does not qualify for the Patient Assistance Program
              (PAP) because their income exceeds the eligibility threshold
            </Typography>
            {selectedMed.name === 'Ozempic' && (
              <Typography variant="h4" fontWeight={500}>
                We also offer a generic version of this medication under the
                name Wegovy which does not require any income nor insurance
                eligibility
              </Typography>
            )}
            {unsupportedInsurances.length === 21 && (
              <Typography variant="h3" fontWeight={500}>
                Please search for an alternative medication in our medication
                list.
              </Typography>
            )}
          </>
        )}
        {scenario === 'novoNotEligible' && (
          <>
            <Typography variant="h3">
              Your client does not qualify for the Patient Assistance Program
              (PAP) due to their current income.
            </Typography>
            {selectedMed.name === 'Mounjaro' ||
              (selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              ))}
            {unsupportedInsurances.length === 21 && (
              <Typography variant="h3" fontWeight={500}>
                Please search for an alternative medication in our medication
                list.
              </Typography>
            )}
          </>
        )}
        {scenario === 'novoEligible' && (
          <>
            <Typography variant="h3">
              Your client does not qualify for the Patient Assistance Program
              (PAP) due to their current insurance product.
            </Typography>
            {selectedMed.name === 'Mounjaro' ||
              (selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              ))}
          </>
        )}
        {scenario === 'rcsEligible' && (
          <>
            <Typography variant="h3" fontWeight={500}>
              Your client does not qualify for the Patient Assistance Program
              (PAP) due to their current insurance product.
            </Typography>

            {selectedMed.name === 'Mounjaro' ||
              (selectedMed.name === 'Ozempic' && (
                <Typography variant="h4" fontWeight={500}>
                  We also offer a generic version of this medication under the
                  name Wegovy which does not require any income nor insurance
                  eligibility
                </Typography>
              ))}
            <Typography variant="h3" fontWeight={500}>
              However, they do qualify for the Real Cost Savings (RCS) program,
              which will provide them with significant savings.
            </Typography>
            <Typography variant="h4">
              The cost for {selectedMed.name} under the current insurance
              product is:
            </Typography>
            <Typography variant="h4">
              {rcsprices &&
                rcsprices.prices.map((price: any) => (
                  <Stack spacing={0} key={price.name} textAlign={'left'} mb={2}>
                    <Typography variant="body1" fontWeight={600}>
                      {price.name}:
                    </Typography>
                    <Typography variant="body1">{price.price}</Typography>
                  </Stack>
                ))}
            </Typography>

            {drawerContent && !isPAP && (
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleDrawer(true)}>
                RCS Extra Information
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={buttonUrl}>
              Enroll Now
            </Button>
          </>
        )}
        {scenario === 'rcsIncomeHigh' && (
          <>
            <Typography variant="h3" fontWeight={500}>
              Your client does not qualify for the Patient Assistance Program
              (PAP) due to their high income.
              <br></br>
              {selectedMed.name === 'Mounjaro' ||
                (selectedMed.name === 'Ozempic' && (
                  <Typography variant="h4" fontWeight={500}>
                    We also offer a generic version of this medication under the
                    name Wegovy which does not require any income nor insurance
                    eligibility
                  </Typography>
                ))}
              <br></br>
              <strong>
                However, they do qualify for the Real Cost Savings (RCS)
                program, which will provide them with significant savings.
              </strong>
            </Typography>
            <Typography variant="h4">
              The cost for {selectedMed.name} under the current insurance
              product is:
            </Typography>
            {rcsprices &&
              rcsprices.prices.map((price: any) => (
                <Stack spacing={0} key={price.name} textAlign={'left'} mb={2}>
                  <Typography variant="body1" fontWeight={600}>
                    {price.name}:
                  </Typography>
                  <Typography variant="body1">{price.price}</Typography>
                </Stack>
              ))}
            {drawerContent && !isPAP && (
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleDrawer(true)}>
                RCS Extra Information
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={buttonUrl}>
              Enroll Now
            </Button>
          </>
        )}
        {scenario === 'rcsOnlyMedAccepted' && (
          <>
            <Typography variant="h3" fontWeight={500}>
              This medication is not covered by the Patient Assistance Program
              (PAP).
            </Typography>

            {selectedMed.name === 'Mounjaro' && (
              <Typography variant="h4" fontWeight={500}>
                We also offer a generic version of this medication under the
                name Wegovy which does not require any income nor insurance
                eligibility
              </Typography>
            )}

            <Typography variant="h3">
              However, they do qualify for the Real Cost Savings (RCS) program,
              which will provide them with significant savings.
            </Typography>

            <Typography variant="h4">
              The cost for {selectedMed.name} under the current insurance
              product is:
            </Typography>
            {rcsprices &&
              rcsprices.prices.map((price: any) => (
                <Stack spacing={0} key={price.name} textAlign={'left'} mb={2}>
                  <Typography variant="body1" fontWeight={600}>
                    {price.name}:
                  </Typography>
                  <Typography variant="body1">{price.price}</Typography>
                </Stack>
              ))}
            {drawerContent && !isPAP && (
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleDrawer(true)}>
                RCS Extra Information
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={buttonUrl}>
              Enroll Now
            </Button>
          </>
        )}
        {scenario === 'rcsOnlyMed' && (
          <>
            <Typography variant="h3">
              This medication is not covered by the Patient Assistance Program
              (PAP).
            </Typography>
            {selectedMed.name === 'Mounjaro' && (
              <Typography variant="h4">
                We also offer a generic version of this medication under the
                name Wegovy which does not require any income nor insurance
                eligibility
              </Typography>
            )}
          </>
        )}
      </Stack>
      {scenario === 'medUnavailable' &&
        insuranceDataList &&
        unsupportedInsurances.length !== 21 && (
          <Stack
            spacing={2}
            sx={{
              width: '100%',
            }}>
            <Divider
              sx={{
                width: '100%',
              }}
            />
            <Stack alignItems={'center'}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={600}
                maxWidth={{
                  xs: '100%',
                  md: '70rem',
                }}>
                However, if your client switches their current insurance product
                to one of the options listed below, they will qualify for the
                Real Cost Savings (RCS) program and receive significant savings.
              </Typography>
            </Stack>
            <Stack
              sx={{
                overflowX: {
                  xs: 'auto',
                  md: 'hidden',
                },
              }}>
              <table
                style={{
                  width: '100%',
                  minWidth: '60rem',
                  borderCollapse: 'collapse',
                  overflowX: 'scroll',
                }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Name
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Product
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Plan
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '40%',
                        textAlign: 'left',
                      }}>
                      Pricing
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {insuranceDataList.map((insurance, insuranceIndex) => {
                    return (
                      <React.Fragment key={insuranceIndex}>
                        {insurance.products
                          ?.filter(
                            (product: any) =>
                              !unsupportedInsurances.includes(product.name)
                          )
                          .map((product: any, productIndex: number) => (
                            <React.Fragment key={productIndex}>
                              {product.plans
                                ?.filter(
                                  (plan: any) =>
                                    plan['REAL_COST_SAVINGS'] === true
                                )
                                .map((plan: any) => {
                                  const row = (
                                    <tr
                                      key={plan.name}
                                      style={{
                                        backgroundColor:
                                          globalIndex % 2 === 0
                                            ? '#f5f5f5'
                                            : '#fff',
                                      }}>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {insurance.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {product.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {plan.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '40%',
                                        }}>
                                        <Typography
                                          variant="body1"
                                          fontWeight={600}>
                                          <Link
                                            onClick={() => {
                                              handleClickOpen({
                                                company: insurance.name,
                                                product: product.name,
                                                plan: plan.name,
                                              });
                                            }}
                                            style={{ cursor: 'pointer' }}>
                                            View price
                                          </Link>
                                        </Typography>
                                      </td>
                                    </tr>
                                  );
                                  globalIndex++; // Increment the global index
                                  return row;
                                })}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </Stack>
          </Stack>
        )}
      {scenario === 'rcsEligible' && insuranceDataList && (
        <Stack
          spacing={2}
          alignItems={'center'}
          sx={{
            width: '100%',
          }}>
          <Divider
            sx={{
              width: '100%',
            }}
          />
          <Stack alignItems={'center'}>
            <Typography
              variant="body1"
              textAlign={'center'}
              fontWeight={600}
              maxWidth={{
                xs: '100%',
                md: '70rem',
              }}>
              If your client prefers the PAP, they have options of the insurance
              products that would qualify listed below:
            </Typography>
          </Stack>
          <Stack
            sx={{
              overflowX: {
                xs: 'auto',
                md: 'hidden',
              },
            }}>
            <table
              style={{
                width: '100%',
                minWidth: '60rem',
                borderCollapse: 'collapse',
                overflowX: 'scroll',
              }}>
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Name
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Product
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Plan
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Pricing
                  </th>
                </tr>
              </thead>
              <tbody>
                {insuranceDataList.map((insurance) => {
                  return (
                    <>
                      {insurance.products
                        ?.filter(
                          (product: any) =>
                            !unsupportedInsurances.includes(product.name)
                        )
                        .map((product: any) => (
                          <>
                            {product.plans
                              ?.filter((plan: any) => plan['PAP'] === true)
                              .map((plan: any) => {
                                const row = (
                                  <tr
                                    key={plan.name}
                                    style={{
                                      backgroundColor:
                                        globalIndex % 2 === 0
                                          ? '#f5f5f5'
                                          : '#fff',
                                    }}>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      <Typography variant="body2">
                                        {insurance.name}
                                      </Typography>
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      <Typography variant="body2">
                                        {product.name}
                                      </Typography>
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      <Typography variant="body2">
                                        {plan.name}
                                      </Typography>
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {selectedMed.options &&
                                        selectedMed.options.map(
                                          (option: any) => (
                                            <Stack
                                              direction={'row'}
                                              spacing={1}
                                              key={option.strength}>
                                              <Typography
                                                variant="body2"
                                                fontWeight={600}>
                                                {option.strength}:
                                              </Typography>
                                              <Typography variant="body2">
                                                {option.price}
                                              </Typography>
                                            </Stack>
                                          )
                                        )}
                                    </td>
                                  </tr>
                                );
                                globalIndex++; // Increment the global index
                                return row;
                              })}
                          </>
                        ))}
                    </>
                  );
                })}
              </tbody>
            </table>
          </Stack>
        </Stack>
      )}
      {scenario === 'papIncomeHigh' &&
        insuranceDataList &&
        unsupportedInsurances.length !== 21 && (
          <Stack
            spacing={2}
            sx={{
              width: '100%',
            }}>
            <Divider
              sx={{
                width: '100%',
              }}
            />
            <Stack alignItems={'center'}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={600}
                maxWidth={{
                  xs: '100%',
                  md: '70rem',
                }}>
                However, if they switch their current insurance product to one
                of the options listed below, they will qualify for the Real Cost
                Savings (RCS) program and receive significant savings.
              </Typography>
            </Stack>
            <Stack
              sx={{
                overflowX: {
                  xs: 'auto',
                  md: 'hidden',
                },
              }}>
              <table
                style={{
                  width: '100%',
                  minWidth: '60rem',
                  borderCollapse: 'collapse',
                  overflowX: 'scroll',
                }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Name
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Product
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Plan
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '40%',
                        textAlign: 'left',
                      }}>
                      Pricing
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {insuranceDataList.map((insurance, insuranceIndex) => {
                    return (
                      <React.Fragment key={insuranceIndex}>
                        {insurance.products
                          ?.filter(
                            (product: any) =>
                              !unsupportedInsurances.includes(product.name)
                          )
                          .map((product: any, productIndex: number) => (
                            <React.Fragment key={productIndex}>
                              {product.plans
                                ?.filter(
                                  (plan: any) =>
                                    plan['REAL_COST_SAVINGS'] === true
                                )
                                .map((plan: any) => {
                                  const row = (
                                    <tr
                                      key={plan.name}
                                      style={{
                                        backgroundColor:
                                          globalIndex % 2 === 0
                                            ? '#f5f5f5'
                                            : '#fff',
                                      }}>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {insurance.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {product.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {plan.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '40%',
                                        }}>
                                        <Typography
                                          variant="body1"
                                          fontWeight={600}>
                                          <Link
                                            onClick={() => {
                                              handleClickOpen({
                                                company: insurance.name,
                                                product: product.name,
                                                plan: plan.name,
                                              });
                                            }}
                                            style={{ cursor: 'pointer' }}>
                                            View price
                                          </Link>
                                        </Typography>
                                      </td>
                                    </tr>
                                  );
                                  globalIndex++; // Increment the global index
                                  return row;
                                })}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </Stack>
          </Stack>
        )}
      {scenario === 'novoNotEligible' &&
        unsupportedInsurances.length !== 21 &&
        insuranceDataList && (
          // if eligible show pap options if not rcs options - make sure it is correct currenyl acusa is showing rcs options
          <Stack
            spacing={2}
            sx={{
              width: '100%',
            }}>
            <Divider
              sx={{
                width: '100%',
              }}
            />
            <Stack alignItems={'center'}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={600}
                maxWidth={{
                  xs: '100%',
                  md: '70rem',
                }}>
                However, if they switch their current insurance product to one
                of the options listed below, they will qualify for the Real Cost
                Savings (RCS) program and receive significant savings.
              </Typography>
            </Stack>
            <Stack
              sx={{
                overflowX: {
                  xs: 'auto',
                  md: 'hidden',
                },
              }}>
              <table
                style={{
                  width: '100%',
                  minWidth: '60rem',
                  borderCollapse: 'collapse',
                  overflowX: 'scroll',
                }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Name
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Product
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '25%',
                        textAlign: 'left',
                      }}>
                      Insurance Plan
                    </th>
                    <th
                      style={{
                        borderBottom: '1px solid black',
                        padding: '8px',
                        backgroundColor: '#fff',
                        width: '40%',
                        textAlign: 'left',
                      }}>
                      Pricing
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {insuranceDataList.map((insurance, insuranceIndex) => {
                    return (
                      <React.Fragment key={insuranceIndex}>
                        {insurance.products
                          ?.filter(
                            (product: any) =>
                              !unsupportedInsurances.includes(product.name)
                          )
                          .map((product: any, productIndex: number) => (
                            <React.Fragment key={productIndex}>
                              {product.plans
                                ?.filter(
                                  (plan: any) =>
                                    plan['REAL_COST_SAVINGS'] === true
                                )
                                .map((plan: any) => {
                                  const row = (
                                    <tr
                                      key={plan.name}
                                      style={{
                                        backgroundColor:
                                          globalIndex % 2 === 0
                                            ? '#f5f5f5'
                                            : '#fff',
                                      }}>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {insurance.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {product.name}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '25%',
                                        }}>
                                        {plan.name !== 'PAP' ? plan.name : ''}
                                      </td>
                                      <td
                                        style={{
                                          padding: '8px',
                                          width: '40%',
                                        }}>
                                        <Typography
                                          variant="body1"
                                          fontWeight={600}>
                                          <Link
                                            onClick={() => {
                                              handleClickOpen({
                                                company: insurance.name,
                                                product: product.name,
                                                plan: plan.name,
                                              });
                                            }}
                                            style={{ cursor: 'pointer' }}>
                                            View price
                                          </Link>
                                        </Typography>
                                      </td>
                                    </tr>
                                  );
                                  globalIndex++; // Increment the global index
                                  return row;
                                })}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </Stack>
          </Stack>
        )}
      {scenario === 'novoEligible' && insuranceDataList && (
        <Stack
          spacing={2}
          sx={{
            width: '100%',
          }}>
          <Divider
            sx={{
              width: '100%',
            }}
          />
          <Stack alignItems={'center'}>
            <Typography
              variant="body1"
              textAlign={'center'}
              fontWeight={600}
              maxWidth={{
                xs: '100%',
                md: '70rem',
              }}>
              However, if they switch their current insurance product to one of
              the options listed below, they will qualify for the PAP program
            </Typography>
          </Stack>
          <Stack
            sx={{
              overflowX: {
                xs: 'auto',
                md: 'hidden',
              },
            }}>
            <table
              style={{
                width: '100%',
                minWidth: '60rem',
                borderCollapse: 'collapse',
                overflowX: 'scroll',
              }}>
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Name
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Product
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Plan
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '40%',
                      textAlign: 'left',
                    }}>
                    Pricing
                  </th>
                </tr>
              </thead>
              <tbody>
                {insuranceDataList.map((insurance, insuranceIndex) => {
                  return (
                    <React.Fragment key={insuranceIndex}>
                      {insurance.products
                        ?.filter(
                          (product: any) =>
                            !unsupportedInsurances.includes(product.name)
                        )
                        .map((product: any, productIndex: number) => (
                          <React.Fragment key={productIndex}>
                            {product.plans
                              ?.filter((plan: any) => plan['PAP'] === true)
                              .map((plan: any) => {
                                const row = (
                                  <tr
                                    key={plan.name}
                                    style={{
                                      backgroundColor:
                                        globalIndex % 2 === 0
                                          ? '#f5f5f5'
                                          : '#fff',
                                    }}>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {insurance.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {product.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {plan.name !== 'PAP' ? plan.name : ''}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '40%',
                                      }}>
                                      {selectedMed.options &&
                                        selectedMed.options.map(
                                          (option: any) => (
                                            <Stack
                                              direction={'row'}
                                              spacing={1}
                                              key={option.strength}>
                                              <Typography
                                                variant="body2"
                                                fontWeight={600}>
                                                {option.strength}:
                                              </Typography>
                                              <Typography variant="body2">
                                                {option.price}
                                              </Typography>
                                            </Stack>
                                          )
                                        )}
                                    </td>
                                  </tr>
                                );
                                globalIndex++; // Increment the global index
                                return row;
                              })}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </Stack>
        </Stack>
      )}
      {scenario === 'papOnlyMedEligible' && insuranceDataList && (
        <Stack
          spacing={2}
          sx={{
            width: '100%',
          }}>
          <Divider
            sx={{
              width: '100%',
            }}
          />
          <Stack alignItems={'center'}>
            <Typography
              variant="body1"
              textAlign={'center'}
              fontWeight={600}
              maxWidth={{
                xs: '100%',
                md: '70rem',
              }}>
              However, if they switch their current insurance product to one of
              the options listed below, they will qualify for the PAP program
            </Typography>
          </Stack>
          <Stack
            sx={{
              overflowX: {
                xs: 'auto',
                md: 'hidden',
              },
            }}>
            <table
              style={{
                width: '100%',
                minWidth: '60rem',
                borderCollapse: 'collapse',
                overflowX: 'scroll',
              }}>
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Name
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Product
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Plan
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '40%',
                      textAlign: 'left',
                    }}>
                    Pricing
                  </th>
                </tr>
              </thead>
              <tbody>
                {insuranceDataList.map((insurance, insuranceIndex) => {
                  return (
                    <React.Fragment key={insuranceIndex}>
                      {insurance.products
                        ?.filter(
                          (product: any) =>
                            !unsupportedInsurances.includes(product.name)
                        )
                        .map((product: any, productIndex: number) => (
                          <React.Fragment key={productIndex}>
                            {product.plans
                              ?.filter((plan: any) => plan['PAP'] === true)
                              .map((plan: any) => {
                                const row = (
                                  <tr
                                    key={plan.name}
                                    style={{
                                      backgroundColor:
                                        globalIndex % 2 === 0
                                          ? '#f5f5f5'
                                          : '#fff',
                                    }}>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {insurance.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {product.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {plan.name !== 'PAP' ? plan.name : ''}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '40%',
                                      }}>
                                      {selectedMed.options &&
                                        selectedMed.options.map(
                                          (option: any) => (
                                            <Stack
                                              direction={'row'}
                                              spacing={1}
                                              key={option.strength}>
                                              <Typography
                                                variant="body2"
                                                fontWeight={600}>
                                                {option.strength}:
                                              </Typography>
                                              <Typography variant="body2">
                                                {option.price}
                                              </Typography>
                                            </Stack>
                                          )
                                        )}
                                    </td>
                                  </tr>
                                );
                                globalIndex++; // Increment the global index
                                return row;
                              })}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </Stack>
        </Stack>
      )}
      {scenario === 'rcsOnlyMed' && insuranceDataList && (
        <Stack
          spacing={2}
          sx={{
            width: '100%',
          }}>
          <Divider
            sx={{
              width: '100%',
            }}
          />
          <Stack alignItems={'center'}>
            <Typography
              variant="body1"
              textAlign={'center'}
              fontWeight={600}
              maxWidth={{
                xs: '100%',
                md: '70rem',
              }}>
              However, if your client switches their current insurance product
              to one of the options listed below, they will qualify for the Real
              Cost Savings (RCS) program and receive significant savings.
            </Typography>
          </Stack>
          <Stack
            sx={{
              overflowX: {
                xs: 'auto',
                md: 'hidden',
              },
            }}>
            <table
              style={{
                width: '100%',
                minWidth: '60rem',
                borderCollapse: 'collapse',
                overflowX: 'scroll',
              }}>
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Name
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Product
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '25%',
                      textAlign: 'left',
                    }}>
                    Insurance Plan
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#fff',
                      width: '40%',
                      textAlign: 'left',
                    }}>
                    Pricing
                  </th>
                </tr>
              </thead>
              <tbody>
                {insuranceDataList.map((insurance, insuranceIndex) => {
                  return (
                    <React.Fragment key={insuranceIndex}>
                      {insurance.products
                        ?.filter(
                          (product: any) =>
                            !unsupportedInsurances.includes(product.name)
                        )
                        .map((product: any, productIndex: number) => (
                          <React.Fragment key={productIndex}>
                            {product.plans
                              ?.filter(
                                (plan: any) =>
                                  plan['REAL_COST_SAVINGS'] === true
                              )
                              .map((plan: any) => {
                                const row = (
                                  <tr
                                    key={plan.name}
                                    style={{
                                      backgroundColor:
                                        globalIndex % 2 === 0
                                          ? '#f5f5f5'
                                          : '#fff',
                                    }}>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {insurance.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {product.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '25%',
                                      }}>
                                      {plan.name}
                                    </td>
                                    <td
                                      style={{
                                        padding: '8px',
                                        width: '40%',
                                      }}>
                                      <Typography
                                        variant="body1"
                                        fontWeight={600}>
                                        <Link
                                          onClick={() => {
                                            handleClickOpen({
                                              company: insurance.name,
                                              product: product.name,
                                              plan: plan.name,
                                            });
                                          }}
                                          style={{ cursor: 'pointer' }}>
                                          View price
                                        </Link>
                                      </Typography>
                                    </td>
                                  </tr>
                                );
                                globalIndex++; // Increment the global index
                                return row;
                              })}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </Stack>
        </Stack>
      )}
      {medicationDialogData && (
        <InsuranceCostDialog
          key={selectedMed.name}
          open={openCostDialog}
          handleClose={handleClose}
          selectedMed={selectedMed}
          insuranceData={medicationDialogData}
        />
      )}
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Stack
          justifyContent={'center'}
          alignItems={'end'}
          p={{
            xs: 2,
            md: 6,
          }}
          spacing={2}
          sx={{
            width: {
              sm: '100%',
              md: '60vw',
            },
            position: 'relative',
          }}
          role="presentation">
          <Button onClick={toggleDrawer(false)} variant="contained">
            Close
          </Button>
          <div dangerouslySetInnerHTML={{ __html: drawerContent! }} />
        </Stack>
      </Drawer>
    </>
  );
}

export default EligibilityCalculatorResults;
