import React, { createContext, useContext } from 'react';
import { useLocalStorage } from '../../utils/utilFuntions';

interface FAContextValue {
	isValidFactor: boolean;
	setValidFactor: React.Dispatch<React.SetStateAction<boolean>>;
}

const faContextState = {
	isValidFactor: false,
	setValidFactor: () => {},
};

const FAContext = createContext<FAContextValue>(faContextState);

export const FAProvider = ({ children }: { children: React.ReactNode }) => {
	const [isValidFactor, setIsValidFactor] = useLocalStorage(
		'isValidFactor',
		false
	);

	// Provide context values
	const contextValue: FAContextValue = {
		isValidFactor,
		setValidFactor: setIsValidFactor,
	};

	return (
		<FAContext.Provider value={contextValue}>{children}</FAContext.Provider>
	);
};

// eslint-disable-next-line react-refresh/only-export-components
export const useFA = () => useContext(FAContext);
