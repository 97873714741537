import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#076890',
			contrastText: '#f0f0f0',
			dark: '#153b61',
			light: '#89aeb7',
		},
		secondary: {
			main: '#9c27b0',
		},
		text: {
			primary: '#191919',
		},
		background: {
			default: '#f5f5f5',
			paper: '#e3e3e3',
		},
		error: {
			main: '#900709',
		},
		action: {
			disabled: '#f0f0f0',
			disabledBackground: '#191919',
		},
	},
	typography: {
		fontFamily: 'Manrope, sans-serif',
		htmlFontSize: 10,
		h1: {
			fontSize: '3.2rem',
			fontWeight: 700,
			lineHeight: 1,
			letterSpacing: '0em',
		},
		h2: {
			fontSize: '2.4rem',
			lineHeight: 1.2,
			fontWeight: 700,
			letterSpacing: '0em',
		},
		h3: {
			fontSize: '2rem',
			lineHeight: 1.25,
			fontWeight: 700,
			letterSpacing: '0em',
		},
		h4: {
			fontSize: '1.6rem',
			lineHeight: 1.33,
			fontWeight: 700,
			letterSpacing: '0em',
		},
		body1: {
			fontSize: '1.6rem',
			letterSpacing: '0em',
			lineHeight: 1.75,
		},
		body2: {
			fontSize: '1.4rem',
			lineHeight: 1.75,
		},
		caption: {
			fontSize: '1rem',
		},
		button: {
			fontSize: '1.6rem',
			lineHeight: 1,
			letterSpacing: '0em',
		},
		overline: {
			fontSize: '1rem',
			lineHeight: 2,
			letterSpacing: '0.25em',
		},
	},
	shape: {
		borderRadius: 4,
	},
	components: {
		MuiContainer: {
			styleOverrides: {
				root: {
					maxWidth: '128rem',
					padding: '0 3.2rem',
					'@media (min-width: 1200px)': {
						maxWidth: '128rem',
					},
					'@media (min-width: 600px)': {
						padding: '0 3.2rem',
					},
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					backgroundColor: 'white',
					boxShadow: 'none',
					border: 'none',
					borderRadius: '1.6rem',
					'&:before': {
						display: 'none',
					},
					'&:first-of-type': {
						borderRadius: '1.6rem',
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					gap: '3.2rem',
					padding: '1.6rem',
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: ({ theme }) => ({
					color: theme.palette.primary.main,
					textDecoration: 'underline',
					transform: 'all ease 0.3s',
					'&:hover': {
						textDecoration: 'underline',
						opacity: 0.8,
					},
				}),
			},
		},
		MuiButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					textTransform: 'none',
					fontWeight: 700,
					fontSize: '1.4rem',
					lineHeight: 1,
					letterSpacing: '0em',
					borderRadius: '10rem',
					backgroundColor: theme.palette.text.primary,
					padding: '1.2rem 1.6rem',
					'&:hover': {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						borderColor: theme.palette.primary.main,
					},
					'&.Mui-disabled': {
						backgroundColor: theme.palette.action.disabled,
						color: theme.palette.action.disabledBackground,
					},
				}),
				outlined: ({ theme }) => ({
					border: `1px solid ${theme.palette.primary.main}`,
					backgroundColor: 'transparent',
					color: theme.palette.primary.main,
					'&:hover': {
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
					},
				}),
			},
		},

		MuiFormLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontWeight: 600,
					color: theme.palette.text.primary,
					fontSize: '1.4rem',
				}),
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontWeight: 600,
					color: theme.palette.text.primary,
					fontSize: '1.4rem',
				}),
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					fontSize: '1.6rem',
					backgroundColor: 'white',
					border: '1px solid #d9d9d9',
					borderRadius: '4px',
					padding: '1.2rem',
					'&.Mui-focused': {
						borderColor: '#red',
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					fontSize: '1.6rem',
					backgroundColor: 'white',
					border: '1px solid #d9d9d9',
					borderRadius: '4px',
					padding: '1.2rem',
				},
				root: {
					'&.Mui-focused ': {
						input: {
							border: 'none',
						},
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				inputRoot: {
					fontSize: '1.6rem',
					backgroundColor: 'white',
					borderRadius: '4px',
					padding: '1.2rem',
					'&.MuiOutlinedInput-root': {
						input: {
							padding: 0,
							backgroundColor: 'transparent',
							border: 'unset',
						},
					},
				},
				root: {
					'&.Mui-focused ': {
						input: {
							border: 'none',
						},
					},
				},
				paper: {
					backgroundColor: 'white',
				},
				tag: ({ theme }) => ({
					backgroundColor: theme.palette.primary.main,
					margin: '0rem',
					marginRight: '1rem',
					color: theme.palette.primary.contrastText,
					'& .MuiChip-deleteIcon': {
						color: theme.palette.primary.contrastText,
						'&:hover': {
							color: theme.palette.background.paper,
						},
					},
				}),
			},
		},

		MuiList: {
			styleOverrides: {
				root: {
					backgroundColor: 'white',
					border: '1px solid #d9d9d9',
					borderRadius: '4px',
					padding: '1.2rem',
				},
			},
		},
	},
});

export default theme;
