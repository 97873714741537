import { PropsWithChildren } from 'react';
import logo from '../../assets/images/full logo.png';
import '../../index.css';
import './ComponenetsStyles/Navigation.css';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase-setup/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFA } from '../Login/TwoFA';
import { Stack } from '@mui/material';
import { navigateWithQuery } from '../../utils/utilFuntions';

const Navigation: React.FC<PropsWithChildren> = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { setValidFactor } = useFA();
	const handleLogout = () => {
		signOut(auth)
			.then(() => {
				setValidFactor(false);
				// Sign-out successful.
				navigate('/');
			})
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			.catch((error) => {
				console.log(error);
				// An error happened.
			});
	};
	return (
		<>
			<section className='navigation-s'>
				<nav className='navigation'>
					<a
						onClick={() => {
							navigateWithQuery(navigate, '/', location);
						}}
						className='nav-logo-link'>
						<img
							src={logo}
							alt='TPRX Logo'
							loading='lazy'
							className='nav-logo'
						/>
					</a>
					<Stack direction='row' spacing={2} alignItems={'center'}>
						<a
							className='nav-link'
							onClick={() => {
								navigateWithQuery(navigate, '/supporthub', location);
							}}>
							Support Hub
						</a>
						<button className='cta-main logout-btn' onClick={handleLogout}>
							Logout
							<svg
								width='15'
								height='9'
								viewBox='0 0 15 9'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
									fill='CurrentColor'
								/>
							</svg>
						</button>
					</Stack>
				</nav>
			</section>
			{props.children}
		</>
	);
};
export default Navigation;
