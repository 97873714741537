/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

interface InsuranceCostDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedMed: any;
  insuranceData: any;
}

const InsuranceCostDialog: React.FC<InsuranceCostDialogProps> = ({
  open,
  handleClose,
  selectedMed,
  insuranceData,
}) => {
  const getMedCost = React.useCallback((medication: any, rowdata: any) => {
    if (!medication) return null;
    const insurance = medication.insurance.find((item: any) => {
      return item.name === rowdata.company;
    });
    if (!insurance) return null;
    const product = insurance.products.find((item: any) => {
      return item.name === rowdata.product;
    });
    if (!product) return null;
    const plan = product.plans.find((item: any) => {
      return item.name === rowdata.plan;
    });

    if (!plan) return null;
    return plan;
  }, []);
  const priceData = getMedCost(selectedMed, insuranceData);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      PaperProps={{ sx: { borderRadius: 4 } }}>
      <DialogContent>
        {priceData ? (
          <Stack spacing={4} p={2} key={selectedMed.name}>
            {priceData.prices.map((price: any, index: number) => (
              <Stack spacing={1} key={price.name + index}>
                <Typography variant="body1" fontWeight={600}>
                  {price.name}
                </Typography>
                <Divider />
                <Typography variant="body1">{price.price}</Typography>
              </Stack>
            ))}
            <Stack
              spacing={1}
              p={2}
              borderRadius={2}
              bgcolor={'#153B61'}
              color={'white'}
              textAlign={'center'}>
              <Typography variant="body1" fontWeight={600}>
                Disclaimer
              </Typography>
              <Divider sx={{ borderColor: 'white' }} />
              <Typography variant="body1">
                This estimation is based on the insurance product you have. The
                final price might change based on the amount of medication
                ordered, your pharmacy, and your insurance.{' '}
                <strong>
                  RCS Eligibility requires the client to have prescription
                  coverage for the medication, no matter how small the coverage
                  amount.
                </strong>
              </Typography>
            </Stack>
            <Button onClick={handleClose} variant="contained" color="primary">
              I Understand
            </Button>
          </Stack>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InsuranceCostDialog;
