/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from 'react';
import { formValues } from '../../../utils/types';

interface DateFieldProps {
  setOpenAgeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (value: string) => void;
  setIsMedicare: (value: boolean) => void;
  calculateAgeEligibility: (
    dob: string,
    minMax: string,
    thresholdAge: number
  ) => boolean;
  disability: boolean;
  errors: { [key: string]: string };
  setRcsOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  values: formValues;
}
dayjs.extend(isBetween);

function DateField({
  setOpenAgeDialog,
  onChange,
  setIsMedicare,
  calculateAgeEligibility,
  disability,
  errors,
  setRcsOnly,
  setErrors,
  values,
}: DateFieldProps) {
  const [value, setValue] = useState<Dayjs | null>(
    values.patient.dob ? dayjs(values.patient.dob) : null
  );

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue) {
      onChange(newValue.format('YYYY-MM-DD'));
      setValue(newValue);
      setIsMedicare(
        !disability
          ? calculateAgeEligibility(newValue!.format('YYYY-MM-DD'), 'min', 65)
          : true
      );
      setRcsOnly(
        calculateAgeEligibility(newValue!.format('YYYY-MM-DD'), 'max', 21)
      );
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Birthday"
          value={value}
          onChange={(e) => {
            if (e && e.isBetween(dayjs().subtract(18, 'year'), dayjs())) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                ['dob']: 'You must be 18 years old or older',
              }));
              setOpenAgeDialog(true);
            }
            handleChange(e);
          }}
          minDate={dayjs().subtract(120, 'year')}
          maxDate={dayjs()}
          name="dob"
          sx={{ width: '100%', marginTop: '1.6rem' }}
          slotProps={{
            textField: {
              variant: 'outlined',
              required: true,
              error: !!errors.dob,
              helperText: errors.dob,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}
export default DateField;
