import {
  Dialog,
  DialogContent,
  Stack,
  Button,
  Typography,
} from '@mui/material';
import { TPRXOrderItem } from '../../../utils/sfFunctions';

function VerificationDialog({
  open,
  handleClose,
  orderItems,
  sendOrder,
}: {
  open: boolean;
  handleClose: () => void;
  orderItems: TPRXOrderItem[];
  sendOrder: () => void;
}) {
  const filteredOrders = orderItems.filter(
    (item) => item.medicationName !== ''
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      PaperProps={{
        sx: {
          borderRadius: 4,
        },
      }}>
      <DialogContent>
        <Stack spacing={3} p={2} alignItems={'center'}>
          <Typography variant="h2" fontWeight={600} textAlign={'center'}>
            You are about to submit your order for the following medications:
          </Typography>
          <Stack spacing={2} alignItems={'center'}>
            {filteredOrders.map((item, index) => (
              <Typography key={index} variant="body1">
                <strong>{item.medicationName}:</strong>{' '}
                {item.medicationStrength}
              </Typography>
            ))}
          </Stack>
          <Typography variant="body2" fontWeight={500} textAlign={'center'}>
            You will have to pay the medications cost to the manufacturer
          </Typography>

          <Stack direction={'row'} spacing={2}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={sendOrder} variant="contained">
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
export default VerificationDialog;
